import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import Head from "../components/Head"
import "../styles/menu.css"

const menu = () => (
  <>
    <Head
      description="No esperes más y conoce nuestro variado menú, no solo vendemos pollo, tenemos carne asada, sobrebarriga, pescado, arroz con pollo y más."
      title="Menú - Asadero RoqueFer"
      keywords="carnes pollos sopas"
    />
    <Layout>
      <main>
        <div className="menu-cards">
          <div className="menu-info">
            <h3>Elige una categoría</h3>
            <a
              href="https://drive.google.com/file/d/1BqenDgPI29mIiyBeC_R2Il18coCoLiE5/view?usp=share_link"
              target="_blank"
            >
              Descarga el menú en PDF
            </a>
          </div>
          <Link to="/menu/pollos">
            <div className="food-card pollos">
              <p>POLLOS</p>
            </div>
          </Link>
          <Link to="/menu/sopas">
            <div className="food-card sopas">
              <p>SOPAS</p>
            </div>
          </Link>
          <Link to="/menu/carnes">
            <div className="food-card carnes">
              <p>CARNES</p>
            </div>
          </Link>
          <Link to="/menu/especiales">
            <div className="food-card especiales">
              <p>ESPECIALES</p>
            </div>
          </Link>
          <Link to="/menu/adiciones">
            <div className="food-card adiciones">
              <p>ADICIONES</p>
            </div>
          </Link>
        </div>
      </main>
    </Layout>
  </>
)

export default menu
